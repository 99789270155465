import Mural from "@/components/Mural.vue";
import OnAndOffButton from "@/components/liveClass/comumBtn/OnAndOffButton.vue";
import CardUser from "@/components/liveClass/Card/CardUser.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";

export default {
  Mural,
  OnAndOffButton,
  CardUser,
  LoadingIcon,
};
