<template>
  <section class="container">
    <Mural
      :breadcrumbs="breadcrumbs"
      :count="settings.nameLive"
      :hasGoBack="false"
      class="q-mb-sm q-mx-md"
    />
    <div class="row q-gutter-sm no-wrap">
      <video
        ref="screenPlayer"
        autoplay
        playsinline
        class="screen_player"
        v-show="shareData.isSharingScreen"
      ></video>
      <div class="fullScreen">
        <q-btn
          size="22px"
          color="white"
          icon="fullscreen"
          @click="fullScreen"
          v-show="shareData.isSharingScreen"
          flat
          round
        />
      </div>
      <audio ref="screenAudioPlayer" v-show="shareData.isSharingScreen"></audio>
      <div
        class="users"
        :class="{ 'screen-share__on': shareData.isSharingScreen }"
      >
        <CardUser
          v-for="(participant, { identity }) in participants"
          :user="participant"
          :key="identity"
          ref="cardUser"
        />
      </div>
    </div>
    <footer class="toolbar">
      <OnAndOffButton
        iconOn="mic"
        iconOff="mic_off"
        :state="stateDevices.microphone"
        size="lg"
        :disable="statePage.isLoading"
        @click="handleMicrophone"
      />
      <OnAndOffButton
        iconOn="videocam"
        iconOff="videocam_off"
        :state="stateDevices.camera"
        size="lg"
        :disable="statePage.isLoading"
        @click="handleCamera"
      />
      <OnAndOffButton
        iconOn="desktop_windows"
        iconOff="desktop_windows"
        :state="stateDevices.capture"
        size="lg"
        :disable="shareData.canIShare || statePage.isLoading"
        v-if="havePermissionToShare"
        @click="handleCapture"
      />
      <q-btn
        color="negative"
        size="lg"
        round
        icon="call_end"
        @click="disconnect"
      />
    </footer>
    <q-inner-loading :showing="statePage.isLoading">
      <div
        class="row justify-center q-my-md"
        style="height: auto; width: 40px; margin: auto"
      >
        <LoadingIcon />
      </div>
    </q-inner-loading>
  </section>
</template>

<script>
import { computed, nextTick, onMounted, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Components from "@/pages/LiveClass/Live/Components.js";
import {
  ConnectionQuality,
  ConnectionState,
  DataPacket_Kind,
  DisconnectReason,
  LocalParticipant,
  MediaDeviceFailure,
  Participant,
  ParticipantEvent,
  RemoteParticipant,
  RemoteTrackPublication,
  RemoteVideoTrack,
  Room,
  RoomConnectOptions,
  RoomEvent,
  RoomOptions,
  Track,
  TrackPublication,
  VideoCaptureOptions,
  VideoCodec,
  VideoPresets,
  VideoQuality,
  LogLevel,
  setLogLevel,
} from "livekit-client";
import { ENV } from "@/utils/env";
import { useQuasar } from "quasar";
import TrailDataService from "@/services/TrailDataServices";

export default {
  name: "Live",
  components: Components,
  setup(props) {
    const route = new useRoute();
    const router = new useRouter();
    const store = new useStore();
    const room = new Room();

    const $q = new useQuasar();
    const trailDataService = new TrailDataService();

    /* VUE */
    const settings = reactive({
      nameLive: store.state.live.name,
      liveId: store.state.live.id,
      userId: store.state.userdata.id,
      token: store.state.live.token,
    });

    const breadcrumbs = ref(["Aula ao vivo"]);
    const participants = ref([]);
    const stateDevices = reactive({
      camera: false,
      microphone: false,
      capture: false,
    });
    const statePage = reactive({ isLoading: false });

    const shareData = reactive({
      isSharingScreen: false,
      canIShare: false,
      fullName: "",
    });

    const cardUser = ref(null);
    const screenPlayer = ref(null);
    const screenAudioPlayer = ref(null);

    $q.notify.registerType("live-notify", {
      color: "#000000CC",
      textColor: "white",
    });

    function logLive() {
      switch (ENV.APP_ENV) {
        case "prod":
          setLogLevel(LogLevel.silent);
          break;
        case "dev":
          setLogLevel(LogLevel.debug);
          break;
        case "local":
          setLogLevel(LogLevel.debug);
          break;
        default:
          break;
      }
    }

    function RoomEvents() {
      room
        .on(RoomEvent.ParticipantConnected, participantConnected)
        .on(RoomEvent.ParticipantDisconnected, participantDisconnected)
        .on(RoomEvent.TrackSubscribed, trackSubscribed)
        .on(
          RoomEvent.TrackUnsubscribed,
          (track, RemoteTrackPublication, RemoteParticipant) => {
            switch (track.source) {
              case Track.Source.ScreenShare:
                unrenderScreenShare(RemoteParticipant, track);
                break;
              default:
                break;
            }
          }
        )
        .on(RoomEvent.ActiveSpeakersChanged, activeSpeakerChange)
        .on(RoomEvent.TrackMuted, trackMuted)
        .on(RoomEvent.TrackUnmuted, trackUnMuted)
        .on(RoomEvent.DataReceived, () => {})
        .on(RoomEvent.Disconnected, () => {})
        .on(RoomEvent.Reconnecting, () => {})
        .on(RoomEvent.Reconnected, () => {})
        .on(RoomEvent.MediaDevicesError, (e) => {
          const failure = MediaDeviceFailure.getFailure(e);
        });
    }

    async function connectToRoom() {
      statePage.isLoading = true;
      RoomEvents();

      try {
        await room.connect(ENV.wsUrl, settings.token);
        participants.value.push(room.localParticipant);
        getRemotesParticipants();
        handleExistentMedias();
        statePage.isLoading = false;
      } catch (error) {
        statePage.isLoading = false;
        if (error.status === 401) {
          store.commit("setLiveData", {
            id: null,
            name: null,
            token: null,
          });
          router.push({ name: "knowledge" });
        }
      }
    }

    async function disconnect() {
      try {
        statePage.isLoading = true;
        await trailDataService
          .disconnectLesson(settings.liveId)
          .then((response) => {
            if (response.data) {
              room.disconnect().then(() => {
                statePage.isLoading = false;
                router.go(-1);
              });
            }
          });
      } catch (error) {
        $q.notify({
          message: error,
          type: "negative",
          position: "top",
        });
        statePage.isLoading = false;
      }
    }

    function participantConnected(RemoteParticipant) {
      participants.value = participants.value.filter((participant, index) => {
        if (RemoteParticipant.identity !== participant.identity) {
          return participant;
        }
      });
      participants.value.push(RemoteParticipant);
      $q.notify({
        message: `${RemoteParticipant.name} acabou de entrar`,
        type: "live-notify",
        position: "top",
      });
    }

    function participantDisconnected(RemoteParticipant) {
      participants.value = participants.value.filter((participant, index) => {
        if (RemoteParticipant.identity !== participant.identity) {
          return participant;
        }
      });
      handleExistentMedias();
      $q.notify({
        message: `${RemoteParticipant.name} acabou de sair`,
        type: "live-notify",
        position: "top",
      });
    }

    function getRemotesParticipants() {
      room.participants.forEach((participant) => {
        participants.value.push(participant);
      });
    }

    function getParticipantIndex(participant) {
      return participants.value.findIndex(
        (p) => p.identity === participant.identity
      );
    }

    function trackSubscribed(trackPublication, publication, participant) {
      switch (trackPublication.source) {
        case Track.Source.Camera:
          renderCamera(participant, trackPublication);
          break;
        case Track.Source.Microphone:
          renderMicrophone(participant, trackPublication);
          break;
        case Track.Source.ScreenShare:
          renderScreenShare(participant, trackPublication);
          break;
        case Track.Source.ScreenShareAudio:
          renderScreenShareAudio(participant, trackPublication);
          break;
        default:
          break;
      }
    }

    function trackMuted(trackPublication, participant) {
      handleExistentMedias();

      switch (trackPublication.source) {
        case Track.Source.Camera:
          unrenderCamera(participant, trackPublication.track);
          break;
        case Track.Source.Microphone:
          unrenderMicrophone(participant, trackPublication.track);
          break;

        default:
          break;
      }
    }

    function trackUnMuted(trackPublication, participant) {
      handleExistentMedias();

      switch (trackPublication.source) {
        case Track.Source.Camera:
          renderCamera(participant, trackPublication.track);
          break;
        case Track.Source.Microphone:
          renderMicrophone(participant, trackPublication.track);
          break;
        case Track.Source.ScreenShare:
          renderScreenShare(participant, trackPublication.track);
          break;
        case Track.Source.ScreenShareAudio:
          renderScreenShareAudio(participant, trackPublication.track);
          break;
        default:
          break;
      }
    }

    function activeSpeakerChange(speakers) {
      if (speakers.length > 0) {
        speakers.forEach((speaker) => {
          reRenderComponent(speaker);
        });
      } else {
        participants.value.forEach((participant) => {
          reRenderComponent(participant);
        });
      }
    }

    function reRenderComponent(participant) {
      if (participants.value.length > 0) {
        const index = getParticipantIndex(participant);

        if (index != -1) {
          cardUser.value[index].$forceUpdate();
        }
      }
    }

    async function renderCamera(participant, track) {
      const index = getParticipantIndex(participant);

      if (index != -1) {
        if (participant.isLocal) {
          // Força o update do componente não reativo
          cardUser.value[index].$forceUpdate();
          /*
           * Aguarda o DOM atualizar totalmente para
           * poder fazer algo depois da promise */
          await nextTick();
          track.attach(cardUser.value[index].localVideo);
        } else {
          await nextTick();
          cardUser.value[index]?.$forceUpdate();
          await nextTick();
          track.attach(cardUser.value[index]?.remoteVideo);
        }
      }
    }

    async function renderMicrophone(participant, track) {
      const index = getParticipantIndex(participant);
      if (index != -1) {
        if (participant.isLocal) {
          cardUser.value[index].$forceUpdate();
        } else {
          await nextTick();
          cardUser.value[index].$forceUpdate();
          await nextTick();
          track.attach(cardUser.value[index].remoteAudio);
        }
      }
    }

    function renderScreenShareAudio(participant, track) {
      track.attach(screenAudioPlayer.value);
    }

    function renderScreenShare(RemoteParticipant, track) {
      shareData.isSharingScreen = true;
      shareData.fullName = RemoteParticipant.name;

      track.attach(screenPlayer.value);

      RemoteParticipant.isLocal
        ? (shareData.canIShare = false)
        : (shareData.canIShare = true);

      $q.notify({
        message: `${RemoteParticipant.name} está compartilhando sua tela!`,
        type: "live-notify",
        position: "top",
      });
    }

    function unrenderScreenShare(RemoteParticipant, track) {
      shareData.isSharingScreen = false;
      $q.notify({
        message: `A tela compartilhada de ${RemoteParticipant.name} foi encerrada!`,
        type: "live-notify",
        position: "top",
      });
      shareData.fullName = "";
      shareData.canIShare = false;
      stateDevices.capture = false;
    }

    async function unrenderCamera(participant, track) {
      const index = getParticipantIndex(participant);

      if (index != -1) {
        if (participant.isLocal) {
          track.detach(cardUser.value[index].localVideo);
          await nextTick();
          cardUser.value[index].$forceUpdate();
        } else {
          track.detach(cardUser.value[index].remoteVideo);
          await nextTick();
          cardUser.value[index].$forceUpdate();
        }
      }
    }

    function unrenderMicrophone(participant, track) {
      const index = getParticipantIndex(participant);
      if (index != -1) {
        cardUser.value[index].$forceUpdate();
        track.detach(cardUser.value[index].remoteAudio);
      }
    }

    function handleExistentMedias() {
      room.participants.forEach((participant) => {
        if (store.state.userdata.id != Number(participant.identity)) {
          participant.getTracks().forEach((track) => {
            switch (track.source) {
              case Track.Source.Camera:
                renderCamera(participant, track.track);
                break;
              case Track.Source.Microphone:
                renderMicrophone(participant, track.track);
                break;
              case Track.Source.ScreenShare:
                renderScreenShare(participant, track.track);
                break;
              case Track.Source.ScreenShareAudio:
                renderScreenShareAudio(participant, track.track);
                break;
              default:
                break;
            }
          });
        }
      });
    }

    function fullScreen() {
      screenPlayer.value.requestFullscreen();
    }

    async function handleCamera() {
      const {
        deviceId,
        resolution: { frameRate, width, height },
      } = store.state.constraints.camera;

      try {
        if (stateDevices.camera) {
          const response = await room.localParticipant.setCameraEnabled(false);

          stateDevices.camera = false;
          unrenderCamera(room.localParticipant, response.track);

          return;
        }

        const response = await room.localParticipant.setCameraEnabled(true, {
          deviceId: deviceId,
          resolution: {
            frameRate: frameRate,
            width: width,
            height: height,
          },
        });

        stateDevices.camera = true;
        renderCamera(room.localParticipant, response.track);
      } catch (error) {
        console.log(error);
        $q.notify({
          message: error,
          type: "negative",
          position: "top",
        });
      }
    }

    async function handleMicrophone() {
      try {
        if (stateDevices.microphone) {
          await room.localParticipant.setMicrophoneEnabled(false);

          stateDevices.microphone = false;
          return;
        }

        const response = await room.localParticipant.setMicrophoneEnabled(
          true,
          store.state.microphone
        );

        stateDevices.microphone = true;
        renderMicrophone(room.localParticipant, response.track);
      } catch (error) {
        $q.notify({
          message: error,
          type: "negative",
          position: "top",
        });
      }
    }

    async function handleCapture() {
      const options = {
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 44100,
          deviceId: store.state.constraints.headphone.deviceId,
        },
        systemAudio: "include",
        surfaceSwitching: "include",
        resolution: {
          width: 1280,
          height: 720,
          frameRate: 60,
        },
      };

      try {
        if (stateDevices.capture) {
          const response = await room.localParticipant.setScreenShareEnabled(
            false,
            options
          );

          stateDevices.capture = false;

          unrenderScreenShare(room.localParticipant, response.track);
          return;
        }

        const response = await room.localParticipant.setScreenShareEnabled(
          true,
          options
        );

        stateDevices.capture = true;

        if (response?.track) {
          renderScreenShare(room.localParticipant, response.track);
        }
      } catch (error) {
        $q.notify({
          message: error,
          type: "negative",
          position: "top",
        });
      }
    }

    onMounted(() => {
      connectToRoom();
      logLive();
    });

    const havePermissionToShare = computed(() => {
      return [1, 2].includes(store.state.userdata.role_id);
    });
    return {
      shareData,
      havePermissionToShare,
      settings,
      breadcrumbs,
      stateDevices,
      cardUser,
      screenPlayer,
      disconnect,
      handleCamera,
      handleMicrophone,
      handleCapture,
      participants,
      router,
      statePage,
      fullScreen,
    };
  },
};
</script>

<style scoped lang="scss">
.container {
  margin-left: 14px;
  margin-right: 14px;
  height: auto;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.users {
  margin: 8px 24px 0 24px;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(1, minmax(300px, 1fr));
  gap: 20px;
}

.screen-share__on {
  grid-template-columns: repeat(1, 1fr);
  gap: 8pxpx;
  align-items: start;
}

.fullScreen {
  position: relative;
  top: 10px;
  right: 80px;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background: rgba(24, 24, 24, 0.6);
  backdrop-filter: blur(4px);
}

.fullName {
  align-self: flex-start;
  position: relative;
  bottom: 20px;
  left: 10px;
  color: var(--grayscale-grayscale-10, #fafafa);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: -0.01563rem;
}

.toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  position: fixed;
  bottom: 3dvh;
  right: 0;
  left: 10%;
}

.screen_player {
  border-radius: 8px;
  margin-left: 16px;
  width: calc(65% - 16px);
  align-self: flex-start;
}

@media (min-width: 700px) and (max-width: 1259px) {
  .users:not(.screen-share__on) {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }
}

@media (min-width: 1260px) and (max-width: 1659px) {
  .users:not(.screen-share__on) {
    grid-template-columns: repeat(3, minmax(300px, 1fr));
  }
}

@media (min-width: 1660px) and (max-width: 2000px) {
  .users:not(.screen-share__on) {
    grid-template-columns: repeat(4, minmax(300px, 1fr));
  }
}

@media (min-width: 2001px) {
  .users:not(.screen-share__on) {
    grid-template-columns: repeat(5, minmax(300px, 1fr));
  }
}

@media (min-width: 2400px) {
  .screen-share__on {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 2550px) {
  .users:not(.screen-share__on) {
    grid-template-columns: repeat(6, minmax(300px, 1fr));
  }
}
</style>
