<template>
  <div>
    <div v-show="user?.isCameraEnabled">
      <div class="card-video">
        <div class="card__header">
          <div class="row q-gutter-sm second-item">
            <div class="bg-icon" v-if="!user.isMicrophoneEnabled">
              <q-icon
                :name="setHeaderState(!user?.isMicrophoneEnabled, 2)"
                class="header__icon"
              />
            </div>
          </div>
        </div>
        <video
          :class="{ speaking: user?.isSpeaking }"
          autoplay
          playsinline
          ref="localVideo"
          v-show="user?.isLocal"
        ></video>
        <video
          :class="{ speaking: user?.isSpeaking }"
          autoplay
          playsinline
          ref="remoteVideo"
          v-show="!user?.isLocal"
        ></video>
        <audio ref="remoteAudio" v-show="!user?.isLocal"></audio>
      </div>
      <p class="fullName">{{ fullName }}</p>
    </div>
    <div v-show="!user?.isCameraEnabled">
      <div class="cardUser" :class="{ speaking: user?.isSpeaking }">
        <div class="cardUser__header">
          <div class="row q-gutter-sm">
            <div class="bg-icon" v-if="!user.isMicrophoneEnabled">
              <q-icon
                :name="setHeaderState(!user.isMicrophoneEnabled, 2)"
                class="header__icon"
              />
            </div>
          </div>
        </div>
        <Avatar :firstName="firstName" :lastName="lastName" class="avatar" />
        <audio autoplay ref="remoteAudio" v-show="!user?.isLocal"></audio>
      </div>
      <p class="fullName">{{ fullName }}</p>
    </div>
  </div>
</template>

<script>
import { computed, defineAsyncComponent, reactive, ref } from "vue";

export default {
  name: "CardUser",
  props: {
    user: Object,
  },
  components: {
    Avatar: defineAsyncComponent(() =>
      import("@/components/liveClass/Avatar/Avatar.vue")
    ),
  },
  setup(props, { emit }) {
    const headerIcons = [
      { icon: "pan_tool" },
      { icon: "push_pin" },
      { icon: "mic_off" },
    ];
    const localVideo = ref(null);
    const remoteVideo = ref(null);
    const remoteAudio = ref(null);

    function setHeaderState(item, index) {
      if (item) return headerIcons[index].icon;
    }

    const firstName = computed(() => {
      if (props.user) {
        const index = props.user?.name.indexOf(" ");
        return props.user?.name.slice(0, index);
      }
    });

    const lastName = computed(() => {
      if (props.user) {
        const index = props.user?.name.indexOf(" ") + 1;
        return props.user?.name.slice(index, props.user?.name.length);
      }
    });

    const fullName = computed(() => props.user.name);

    return {
      firstName,
      lastName,
      fullName,
      setHeaderState,
      localVideo,
      remoteVideo,
      remoteAudio,
    };
  },
};
</script>

<style lang="scss" scoped>
.cardUser {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 317px;
  max-width: 317px;
  min-height: 178.31px;
  max-height: 269px;
  border-radius: 8px;
  border: 1px solid var(--Grayscale-Grayscale-40, #ccc);
  background: linear-gradient(
      180deg,
      rgba(242, 242, 242, 0) 69.44%,
      rgba(24, 24, 24, 0.6) 98.02%
    ),
    #f2f2f2;

  &__header {
    display: flex;
    justify-content: space-between;
    margin: 8px 8px 0 8px;
    position: relative;
    top: -35px;
  }

  .avatar {
    align-self: center;
  }
}

.bg-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: rgba(24, 24, 24, 0.5);
}

.header__icon {
  color: var(--Grayscale-Grayscale-10, #fafafa);
  &--hand {
    margin-right: 2px;
  }
}

.speaking {
  width: 99%;
  border: 2px solid #198f51;
}

.fullName {
  align-self: flex-start;
  position: relative;
  bottom: 20px;
  left: 10px;
  color: var(--grayscale-grayscale-10, #fafafa);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: -0.01563rem;
}

.card-video {
  transition: 0.2s all;
  min-width: 317px;
  max-width: 317px;
  min-height: 178.31px;
  max-height: 269px;
  position: relative;

  .card__header {
    position: absolute;
    top: 0;

    .second-item {
      position: relative;
      left: 9px;
      top: 9px;
    }
  }
}

video {
  width: 100%;
  border-radius: 8px;
}
</style>
